var pawLibrary = {
	map: false,
	loader: $('#loader'),
	selectTxt: $('#selectText'),
	blank: $('#blank'),
	delay: false,
	number: 2,
	autocomplete: [],
	logout: function() {
		$('a.logout-btn').click(function(e) {
			var target = this.href;
			e.preventDefault();
			$.ajax({
        cache: false,
        url: './ajax/dialogLogout.inc.php',
        success:function(data){
					$.fancybox(data, {
						padding:0,
						closeBtn: false,
						afterShow: function() {
							$('#yes').click(function() {
								window.location = target;
							});
							$('#no').click(function(e) {
								e.preventDefault();
								$.fancybox.close();
							});
						}
					});
				}
			});
		});
	},
	success: function() {
		var next = window.parent.$('#next').html();
		var cat =  window.parent.$('#catId').html();
		var id = $('#id').html();

		$.ajax({
      cache: false,
      url: './ajax/dialogSuccess.inc.php?success=1&category=' + cat + '&course=' + id,
      success:function(data){
				parent.$.fancybox(data, {
					padding:0,
					closeBtn: false,
					afterShow: function() {
						window.parent.$('#next-btn').click(function(e) {
							e.preventDefault();
							window.parent.location = next;
						});
					}
				});
			}
		});
	},
	failed: function() {
		pawDraw.current = 1;
		pawDraw.active = [];
		$.ajax({
      cache: false,
      url: './ajax/dialogTryAgain.inc.php',
      success:function(data){
				parent.$.fancybox(data, {
					padding:0,
					closeBtn: false,
					afterShow: function() {
						window.parent.$('#tryAgainBtn').click(function(e) {
							e.preventDefault();
							pawDraw.clearPaths();
							parent.$.fancybox.close();
						});
					}
				});
			}
		});
	},
	leaveFormDialog: function() {
		$('a').click(function(e) {
			var target = this.href;
			e.preventDefault();
			$.ajax({
        cache: false,
        url: './ajax/dialogForm.inc.php',
        success:function(data){
					$.fancybox(data, {
						padding:0,
						closeBtn: false,
						afterShow: function() {
							$('#yes').click(function() {
								window.location = target;
							});
							$('#no').click(function(e) {
								e.preventDefault();
								$.fancybox.close();
							});
						}
					});
				}
			});
		});
	},

	getUrlParameter: function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
	},
	getSearchParameters: function() {
    var prmstr = window.location.search.substr(1);
    return prmstr != null && prmstr != "" ? pawLibrary.transformToAssocArray(prmstr) : {};
	},
	transformToAssocArray: function(prmstr) {
    var params = {};
    var prmarr = prmstr.split("&");
    for ( var i = 0; i < prmarr.length; i++) {
        var tmparr = prmarr[i].split("=");
        params[tmparr[0]] = decodeURIComponent(tmparr[1]);
    }
    return params;
	},
	stripHTML: function(dirtyString) {
	  var container = document.createElement('div');
	  var text = document.createTextNode($.trim(dirtyString));
	  container.appendChild(text);
	  return container.innerHTML; // innerHTML will be a xss safe string
	},
	mobileNav: function()
	{
		var btn = $('#mobile-btn');
		var target = $('#mobile-menu');
	},
	getCookie: function(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length,c.length);
        }
    }
    return "";
	},
	setCookie: function(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays*24*60*60*1000));
		var expires = "expires="+ d.toUTCString();
		document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
	},
	toggleTypeface: function() {
		var cursive = 1;

		var toggle = $('#trigger');
		if(toggle.hasClass('joined') == true) {
			cursive = 0;
			toggle.removeClass('joined').addClass('precursive');
		} else {
			toggle.removeClass('precursive').addClass('joined');
		}

		pawLibrary.setCookie('cursive', cursive, 31);

		// UPDATE COURSE SELECT
		var items = $('.course-select li');
		$.each(items, function(key,row) {
			var row = $(row);
			if(cursive == 1) {
				row.removeClass('precursive').addClass('joined');
			} else {
				row.removeClass('joined').addClass('precursive');
			}
		});

		// UPDATE PREVIEW TEXT
		var items = $('.category-select .preview');
		$.each(items, function(key,row) {
			var row = $(row);
			if(cursive == 1) {
				row.removeClass('precursive').addClass('joined');
			} else {
				row.removeClass('joined').addClass('precursive');
			}
		});
	},
	drawActionButtons: function() {
		// set the pens
		var chooseBtn = $('.choose');
		chooseBtn.click(function() {
			var selected = $(this);
			var color = selected.attr('id');
			chooseBtn.removeClass('active-black active-blue active-green');

			if(color == 'green') {
				var hex = '#62A716';
				selected.addClass('active-green');
			} else if(color == 'blue') {
				var hex = '#719DCF';
				selected.addClass('active-blue');
			} else {
				var hex = '#000';
				selected.addClass('active-' + color);
			}

			for(var x = 0 ; x < 2; x++) {
				window.frames[x].window.pawDraw.color = hex;
			}

			pawLibrary.setCookie('color', color, 31);
			pawDraw.path[pawDraw.current].strokeColor = window.frames[0].window.pawDraw.color;
		});

		// look for audio
		$('#play-audio-btn').click(function() {
			document.getElementById('audio-file').play();
		});

		$('#play-video-btn').click(function() {
			window.frames[0].window.pawDraw.triggerVideo();
		});
	},
	lockLayout: function() {
		var width = $(window).width();
		var pageWrap = $('#page-inner-wrap');
		var sticky = $('.sticky');
				sticky.Stickyfill();
				sticky.Stickyfill().stop();

		if((sticky.length > 0) && (width > 700)) {
			var h = $('#content')[0].clientHeight;
			pageWrap.css('minHeight', h + 'px');
			sticky.Stickyfill().init();
		} else {
			pageWrap.css('minHeight', 'auto');
			sticky.Stickyfill().stop();
		}
	},

	setViewportSize: function() {
		if($('#targetWidth').length > 0 )
		{
			var width = $('#importDraw').contents().find('#width').html();
					width = parseInt(width);
			var height = 560;
			var targetWidth = document.getElementById('targetWidth').clientWidth;
			var viewportHeight = parseInt((height / width) * targetWidth) + 20; // add padding!
			var viewportScale = parseFloat(targetWidth / width);
			var height = document.body.clientHeight;
			var demo = false;

			if($('#importDemo').length == 1)
			{
				demo = true;
			}

			// scale height
			if(height <= 1036) {
				viewportScale = (targetWidth < width) ? (viewportScale) : 0.8;
				if(demo == true) {
					// CHECK IF PORTRAIT
					if(window.innerHeight > window.innerWidth){
						viewportScale = 0.65;
					}
					window.document.getElementById("importDraw").setAttribute('style',  'width:' + width + 'px; -ms-transform: scale(' + viewportScale + '); -moz-transform: scale(' + viewportScale + '); -o-transform: scale(' + viewportScale + '); -webkit-transform: scale(' + viewportScale + '); transform: scale(' + viewportScale + ');');
					window.document.getElementById("importDemo").setAttribute('style',  'width:' + width + 'px; -ms-transform: scale(' + viewportScale + '); -moz-transform: scale(' + viewportScale + '); -o-transform: scale(' + viewportScale + '); -webkit-transform: scale(' + viewportScale + '); transform: scale(' + viewportScale + ');');
				} else {
					window.document.getElementById("importDraw").setAttribute('style',  'width:' + width + 'px; -ms-transform: scale(' + viewportScale + '); -moz-transform: scale(' + viewportScale + '); -o-transform: scale(' + viewportScale + '); -webkit-transform: scale(' + viewportScale + '); transform: scale(' + viewportScale + ');');
				}
			}

			if(targetWidth < width) {
				window.document.getElementById("importDraw").setAttribute('style', 'width:' + width + 'px; -ms-transform: scale(' + viewportScale + '); -moz-transform: scale(' + viewportScale + '); -o-transform: scale(' + viewportScale + '); -webkit-transform: scale(' + viewportScale + '); transform: scale(' + viewportScale + '); -ms-transform-origin: 0 50%; -moz-transform-origin:0 50%; -o-transform-origin:0 50%; -webkit-transform-origin:0 50%; transform-origin:0 50%;');
				if(this.demo == true) {
					window.document.getElementById("importDemo").setAttribute('style', 'width:' + width + 'px; -ms-transform: scale(' + viewportScale + '); -moz-transform: scale(' + viewportScale + '); -o-transform: scale(' + viewportScale + '); -webkit-transform: scale(' + viewportScale + '); transform: scale(' + viewportScale + '); -ms-transform-origin: 0 50%; -moz-transform-origin: 0 50%; -o-transform-origin: 0 50%; -webkit-transform-origin: 0 50%; transform-origin: 0 50%;');
				}
			}

			document.ontouchmove = function(event){
	    	event.preventDefault();
			}
		}

	},
	init: function()
	{
		if($('.logout-btn').length > 0) {
			pawLibrary.logout();
		}

		$('#cursive, #precursive').click(function(e) {
			pawLibrary.toggleTypeface();
		});

		$('#switchTrigger').click(function() {
			var toggle = $('#trigger');
			if(toggle.hasClass('joined') == true) {
				$('#precursive').trigger('click');
			} else {
				$('#cursive').trigger('click');
			}
		});

		if($('.choose').length > 0) {
			pawLibrary.drawActionButtons();
		}

		pawLibrary.lockLayout();

		if($('#enter-word-frm').length > 0)
		{
			var word = $('#word_txt');
			var placeholder = word.attr('placeholder');

			word.click(function(e) {
				word.attr('placeholder', '');
			});

			word.blur(function(e) {
				if(word.val() == '') {
					word.attr('placeholder', placeholder);
				}
			});

			$('#enter-word-btn').click(function(e) {
				e.preventDefault();
				var word = $('#word_txt').val();
						word = word.toLowerCase();
						word = word.replace(/[^a-zA-Z]+/g, '');
				var url = window.location.href;
						url = url.split('?');
						url = url[0];
				window.location = url + "?word=" + word;
			});
		}


	}

}

$(window).on('load', function() {
	pawLibrary.init();

	// Set dimensions
	pawLibrary.setViewportSize();
});

$(window).on('resize', function() {
	pawLibrary.lockLayout();
	if($('#targetWidth').length > 0) {
		pawLibrary.setViewportSize();
	}
});

$(window).bind('orientationchange', function() {
	if($('#targetWidth').length > 0) {
		pawLibrary.setViewportSize();
	}
});
