var pawDraw = {
	canvas: false,
	letter: 0,
	hitpoints: false,
	path: [],
	pathTarget: 0,
	target: 0,
	end: 0,
	errors: 0,
	strokeColor: 'black',
	strokes: 0,
	strokeWidth: 20,
	word: false,
	height: 560,
	width: 0,
	total: 0,
	current: 1,
	start: 0,
	marker: false,
	color: '#000',
	order: [],
	cursive: 0,
	active: [],
	precursiveStroke: 1,
	maxStrokesPerLtr: 1,
	tracking: '',
	trackingScale: '',
	scale: [],
	friction: 0.1,
	diameter: 30,
	demo: false,
	transform: [],
	init: function() {
		this.strokes = $('#strokes').html();
		this.width = parseFloat($('#width').html());
		this.cursive = parseInt($('#cursive').html());

		if($('#watch').length == 1)
		{
			this.demo = true;
		}

		window.parent.document.getElementById("importDraw").style.width = this.width + "px";
		if(this.demo == true) {
			window.parent.document.getElementById("importDemo").style.width = this.width + "px";
		}

		this.canvas = document.getElementById('canvas-1');

		paper.install(window);
		paper.setup(this.canvas);

		view.draw();

		var svg = document.getElementById('svg');
		this.word = project.importSVG(svg);
		this.word.visible = true; // Turn off the effect of display:none;
		this.word.fillColor = '#D9D9D9';
		this.word.view.viewSize.width = this.width;

		this.hitpoints = project.importSVG(document.getElementById('hitpoints'));
		this.hitpoints.visible = false;

		// create sort order for strokes
		var length = this.hitpoints.children.length;

		// set up cursive hitpoints order
		if(this.cursive == 1)
		{
			for(s = 1; s <= this.strokes; s++) {
				pawDraw.order[s] = [];
				for(w = 0; w < length; w++) {
					pawDraw.order[s][w] = [];
				}
			}

			// set sort order
			for(w = 0; w < length; w++)
			{
				for(p = 0; p < this.hitpoints.children[w].children.length; p++)
				{
					var point = this.hitpoints.children[w].children[p];
					var strokeOrder = point.style.strokeWidth;
					if(pawDraw.order[strokeOrder][w] == false) {
						pawDraw.order[strokeOrder][w] = [];
					}
					pawDraw.order[strokeOrder][w].push(point.id);
				}
			}
			//
		} else {
			// precusrive hit points
			// set sort order
			for(w = 0; w < length; w++)
			{
				pawDraw.order[w] = [];
				for(p = 0; p < this.hitpoints.children[w].children.length; p++)
				{
					var point = this.hitpoints.children[w].children[p];
					var strokeOrder = point.style.strokeWidth;
					if (typeof pawDraw.order[w][strokeOrder] == "undefined" || !(pawDraw.order[w][strokeOrder] instanceof Array)) {
						pawDraw.order[w][strokeOrder] = [];
					}
					if(strokeOrder > pawDraw.maxStrokesPerLtr) pawDraw.maxStrokesPerLtr = strokeOrder;
					pawDraw.order[w][strokeOrder].push(point.id);
				}
			}
		}


		var lookUpX = $('#svg svg');
		for(x = 0; x < this.word.children.length; x++) {
			var tmp = $(lookUpX[x]).attr('x');
			this.word.children[x].position.x = tmp;
			this.hitpoints.children[x].position.x = tmp;
			this.end += this.hitpoints.children[x].children.length; // count the hotspots
		}

		if(this.demo == false)
		{
			view.onMouseDrag = function(event) {
				pawDraw.onMouseDrag(event);
			}
			view.onMouseDown = function(event) {
				pawDraw.onMouseDown(event);
			}
			view.onMouseUp = function(event) {
				pawDraw.onMouseUp(event);
			}
			pawDraw.initPath();
			pawDraw.setStrokeColor();
		} else {
			setTimeout(function(){
				pawDraw.triggerVideo();
			}, 2000);
		}

	},
	getParameterByName: function(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
	},
	initPath: function() {

		pawDraw.path[pawDraw.current] = new Path({
			strokeColor: pawDraw.color,
			strokeWidth: pawDraw.strokeWidth,
			strokeCap: 'round'
		}).bringToFront();

		var x = pawDraw.hitpoints.children[0].children[0].position.x;
		var y = pawDraw.hitpoints.children[0].children[0].position.y;

		pawDraw.marker = new Path.Circle(new Point(x, y), 15);
  	pawDraw.marker.fillColor = '#C53838';
		pawDraw.marker.position = pawDraw.hitpoints.children[0].children[0].position;
		pawDraw.path[pawDraw.current].add(x, y);

		this.pathTarget = 0;
		if(this.cursive == 1) {
			this.active = this.lookUpNextTarget(this.order[this.current][this.letter][this.target]);
			for(var y = 0; y < this.word.children.length; y++) {
				this.pathTarget += this.order[this.current][y].length;
			}
		} else {
			this.active = this.lookUpNextTarget(this.order[this.letter][this.current][this.target]);
			this.pathTarget += this.order[this.letter][this.current].length;
		}

	},
	checkBounds: function(point, target) {
		var insideOutside = false;

		// range to set if its outside
		// changed the range from 20 to 32.5 to make it easier
		for(var z = -32.5; z < 32.5; z++) {
			var x = point.x + z;
			var y = point.y + z;
			var tmp = target.contains(x, y);
			if(tmp == true) {
				insideOutside = true;
			}
		}

		return insideOutside;
	},
	lookUpNextTarget: function(id)
	{
		for(w = 0; w < this.hitpoints.children.length; w++)
		{
			for(p = 0; p < this.hitpoints.children[w].children.length; p++)
			{
				var point = this.hitpoints.children[w].children[p];
				if(point.id == id) {
					return point;
				}
			}
		}

		return [];
	},
	onMouseDrag: function(event) {

		if(this.total <= this.pathTarget)
		{
			this.path[this.current].add(event.point);
	    //this.path[this.current].smooth();
	    this.marker.position = event.point;


	    // check bounds
			if(this.total < this.pathTarget)
			{
		    var insideOutline = this.checkBounds(event.point, this.word);
		    if(insideOutline == false) {
		      this.errors++;
		      this.path[this.current].strokeColor = "#C53838"; // error
		    }
			}

			// pick new hitpoint when coing to the end
			if(this.active.length == 0) {
	      if(this.cursive == 1) {
					// letters...
					if(this.letter < this.order[this.current].length)
					{
					  this.active = this.lookUpNextTarget(this.order[this.current][this.letter][this.target]);
				    this.pathTarget = 0;
				    for(var y = 0; y < this.word.children.length; y++) {
				      this.pathTarget += this.order[this.current][y].length;
				    }
					}
	      } else {
	        this.active = this.lookUpNextTarget(this.order[this.letter][this.precursiveStroke][this.target]);
	        this.pathTarget = 0;
	        for(var y = 1; y < this.order[this.letter].length; y++) {
	          this.pathTarget += this.order[this.letter][y].length;
	        }
	      }

	    } else {
	      var checkActiveBounds = this.checkBounds(event.point, this.active); //
	      if(checkActiveBounds == true) {
	        this.active.fillColor = 'green';
	        this.target++;
	        this.total++;
	        if(this.cursive == 1) {
	          this.active = this.lookUpNextTarget(this.order[this.current][this.letter][this.target]);
	          var nxt = this.order[this.current][this.letter].length;
						while(nxt == 0) {
							this.letter++;
							nxt = this.order[this.current][this.letter].length;
						}
	        } else {
	          var nxt = this.order[this.letter][this.precursiveStroke].length;
	          if(this.target < nxt) {
	            this.active = this.lookUpNextTarget(this.order[this.letter][this.precursiveStroke][this.target]);
	          }

	        }

	        if(this.target == nxt) { // all hitpoints are hit
	          if(this.cursive == 1) {
	            if(this.current == 1) this.total++;
	            this.target = 1;
	            if((this.current == 1) && (this.total >= this.pathTarget)) {
	              this.target = 0;
	              this.letter = -1; // reallllllly hacky... put back to zero once bug is found
	            }
	            if(this.hitpoints.children.length > 1) {
								this.letter++;
							}
	          }
	        }
	        // check this
	        if(this.total > this.pathTarget) {
	          this.total = this.pathTarget;
	        }
	      }
	    }


		}
	},
	onMouseDown: function(event) {
		if(this.current == 1) {
			pawDraw.clearPaths();
		} else {
			// validate a drag
			pawDraw.onMouseDrag(event);
		}
	},
	onMouseUp: function(event) {
		this.path[this.current].smooth();

		if(this.errors == 0) {
			if(this.total >= this.pathTarget) {
				// validate completion
				if(this.current == this.strokes) {
					setTimeout(function () {
						pawLibrary.success()
					}, 500);
				} else {
					// look up next stroke
					this.current++;
					this.target = 0;
					this.total = 0;
					this.active = [];
					this.pathTarget = 0;
					// cusrive type
					if(this.cursive == 1) {
						for(y = 0; y < this.hitpoints.children.length; y++) {
							if(this.order[this.current][y].length > 0) {
								this.active = this.lookUpNextTarget(this.order[this.current][y][this.target]);
								this.pathTarget += this.order[this.current][y].length;
							}
						}
					} else {
						// precursive type
						this.precursiveStroke++;
						if(typeof this.order[this.letter][this.precursiveStroke] == "undefined") {
							this.precursiveStroke = 1;
							this.letter++;
						}
						this.active = this.lookUpNextTarget(this.order[this.letter][this.precursiveStroke][this.target]);
						this.pathTarget = this.order[this.letter][this.precursiveStroke].length;
					}

					this.path[this.current] = new Path({
						strokeColor: pawDraw.color,
						strokeWidth: pawDraw.strokeWidth,
						strokeCap: 'round'
					});

					if(this.active != false)
					{
						this.marker.position = this.active.position;
						this.marker.bringToFront();
						this.path[this.current].add(this.active.position);
						// chk if new path appears on hotspot
						if(this.active.contains(this.active.position) == true) {
							// inside
							this.active.fillColor = 'green';
							if((this.cursive == 1) && (this.current == 1) && (this.hitpoints.children.length > 1)) {
								// skip over first hotspots of each incment letter
								this.target++;
								this.total++;
							}
						}
					}
				}
			} else {
				// if mouse up doesn't match
				pawDraw.clearPaths();
				pawLibrary.failed();
			}
		} else {
			// if they're outside the line
			pawDraw.clearPaths();
			pawLibrary.failed();
		}
	},
	setStrokeColor: function() {
		// lookup cookies
		if(window.parent.$('.choose').length > 0)
		{
			var previousColour = pawLibrary.getCookie('color');
			if(previousColour != '') {
				var lookUpColor = window.parent.$('#' + previousColour);
				if(lookUpColor.length == 1) {
					window.parent.$('#' + previousColour).trigger('click');
				}
			}
		}
	},
	clearPaths: function() {
		var count = pawDraw.path.length;
		for(x = 1; x < count; x++) {
			pawDraw.path[x].clear();
		}
		if(pawDraw.marker != false) {
			pawDraw.marker.remove();
		}
		this.target = 0;
		this.total = 0;
		this.letter = 0;
		this.hitpoints.fillColor = '#C53838';
		this.word.fillColor = '#D9D9D9';
		this.errors = 0;
		this.current = 1;
		this.active = [];
		this.precursiveStroke = 1;

		pawDraw.initPath();
	},
	setPosition: function(el, x, y){
		var css = 'translate3d(' + x + 'px, ' + y + 'px,0)';
		pawDraw.setCss(el,css);
	},
	setScale: function(el, scale){
		var radius =  pawDraw.diameter * 0.5;
		var css = 'translate3d(' + -radius + 'px, ' + -radius + 'px,0) scale(' + scale + ', ' + scale + ')';
		pawDraw.setCss(el,css);
	},
	setCss: function(el, css){
		el.style.MozTransform = css;
		el.style.msTransform = css;
		el.style.OTransform = css;
		el.style.webkitTransform = css;
		el.style.transform = css;
	},
	onStrokeUpdate: function(path){
		var position = path.position;
		var el = pawDraw.tracking[path.index];
		path.position.x += pawDraw.transform[path.index].x;
		pawDraw.setPosition(el, position.x, position.y);
	},
	onTick: function(){
		for (var i = pawDraw.trackingScale.length - 1; i >= 0; i--) {
			var el = pawDraw.trackingScale[i];
			if(Math.abs(pawDraw.scale[i].target - pawDraw.scale[i].current) > 0.0001){
				pawDraw.scale[i].current += (pawDraw.scale[i].target - pawDraw.scale[i].current) * pawDraw.friction;
				pawDraw.setScale(el, pawDraw.scale[i].current);
			}
		}
	},
	tick: function() {
		window.requestAnimationFrame(pawDraw.tick);
		pawDraw.onTick();
	},
	triggerVideo: function() {
		var height = 430;
		var compoundPaths = $('#compound path');
		var watch = $('#watch');

		var pathObj = {
			'watch': {
				"strokepath": [],
				"dimensions": { "width": this.width, 'height': height }
			},
		};

		var sortOrder = 0;
		for(var x = 0; x < compoundPaths.length; x++)
		{
			var selected = $(compoundPaths[x]);
			if(selected.attr('data-sortorder') > sortOrder) {
				sortOrder = selected.attr('data-sortorder');
			}
		}

		for(var y = 1; y <= sortOrder; y++)
		{
			for(var x = 0; x < compoundPaths.length; x++)
			{
				var selected = $(compoundPaths[x]);
				var tmp = selected.attr('data-sortorder');
				if(tmp == y) {
					var delay = (y > 1) ? 500 : 0;
					var duration = parseInt(selected.attr('data-speed'));
					duration = duration * 3;
					var result = selected.attr('transform').split("translate(");
							result = result[1].split(")");
							result = result[0].split(", ");
					pawDraw.transform.push({'x': parseFloat(result[0]), 'y': parseFloat(result[1])});
					pathObj.watch.strokepath.push({
							"path": selected.attr('d'),
							"duration": duration,
							"delay" : delay,
							'onStrokeUpdate' : pawDraw.onStrokeUpdate
					});
				}
			}
		}

		watch.attr('style', 'width:'+this.width+'px;');
		watch.lazylinepainter('destroy');
		watch.lazylinepainter({
				"svgData": pathObj,
				"strokeWidth": 19,
				"strokeColor": "#262213",
				"ratioScale" : 1,
				'drawSequential': true,
				'onStart': function() {
						var watch = $('#watch path');
						watch.each(function(key,row) {
							var row = $(row);
							row.attr('transform', 'translate(' + pawDraw.transform[key].x + ', 0)');
						});

				},
				'onComplete': function() {

				},
				'onStrokeStart' : function(path){
					pawDraw.scale[path.index].target = 1;
					pawDraw.tracking[path.index];
				},
				'onStrokeComplete' : function(path){
					pawDraw.scale[path.index].target = 0;
					var position = path.position;
					var el = pawDraw.tracking[path.index];
					pawDraw.setPosition(el, position.x, position.y);
				}
		});

		watch.lazylinepainter('paint');

		var data = watch.lazylinepainter('get');
		var body = $('#watchWrap');

		for (var i = data.paths.length - 1; i >= 0; i--) {
			pawDraw.scale.push({ current:0, target:0});
			body.append("<div class='tracking'><div class='tracking__scale'></div></div>");
		};

		pawDraw.tracking = $('.tracking');
		pawDraw.trackingScale = $('.tracking__scale');

		pawDraw.tick();

	},

}

$(document).ready(function() {
	if($('#svg').length > 0) {
		pawDraw.init();

		var loading = $('#loading', window.parent.document.body);
		if(loading.length > 0) {
			setTimeout(function() {
				loading.remove();
			}, 1000);
		}


	}
});
